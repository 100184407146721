import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import Swal from 'sweetalert2'

function SinglePrice(props) {

    async function PaymentStripe() {
        const session = JSON.parse(localStorage.getItem('sb-fjuizzmuqvpsqhjdapfj-auth-token'))
        const JWT = session.access_token
        const success_url = `${window.location.origin}/profile`
        const cancel_url = `${window.location.origin}/price`
        const price = 'price_1NaaKoFJG3GdNqtH4j8gzxBn'

        const Price_obj = { success_url: success_url, cancel_url: cancel_url, price: price }

        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/stripe/pay', {
            method: 'POST',
            body: JSON.stringify(Price_obj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerKey
            },
        })

        const result = await res.json()

        window.location.href = result

    }

    return (
        <div class={props.check == 1 ? 'py-5 w-[50vh] h-fit border-[4px] border-[#C58940] rounded-lg shadow-xl mr-5 mb-5' : 'w-[50vh] h-[70vh] border-[2px]  rounded-lg shadow-xl py-5 mr-5 mb-5'}>
            <div class='w-full h-full flex flex-col justify-between '>
                <div class={props.check == 1 ? 'w-full flex flex-col justify-center items-center space-y-2 border-b-[4px] border-[#C58940] pb-2' : 'w-full flex flex-col justify-center items-center space-y-2 border-b-[2px] pb-2'}>
                    <text class='sm:text-[3vh] font-font2'>Standard</text>
                    <div class='flex flex-col items-center'>
                        <text class='sm:text-6xl text-4xl font-font1 font-bold text-[#E5BA73]'>£10</text>
                        <text class='text-black/50'>per month</text>
                    </div>
                </div>
                <div class='w-full h-full flex flex-col justify-between px-5 pt-5 space-y-20'>
                    <div class='w-full flex-col flex space-y-2'>
                        <text class='flex items-center sm:text-lg font-font2 '>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Generate social post
                        </text>
                        <text class='flex items-center sm:text-lg font-font2'>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Generate advertising script
                        </text>
                        <text class='flex items-center sm:text-lg font-font2'>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Generate product/service description
                        </text>
                        <text class='flex items-center text-lg font-font2'>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            7 day free trial
                        </text>
                    </div>
                    <button class={props.check != 1 ? 'w-full py-3 rounded-lg bg-white border-[2px] border-[#E5BA73] text-[#E5BA73] font-font1 font-bold text-lg' : 'w-full py-3 rounded-lg text-white bg-gradient-to-r from-[#E5BA73] to-[#C58940] font-font1 font-bold text-lg'} onClick={PaymentStripe}>Start free trial</button>
                </div>
            </div>
        </div>
    )
}

export default SinglePrice