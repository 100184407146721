import React,{useState} from 'react'
import logo from '../Images/logo.png'
import supabase from '../config/Supabase'
import Swal from 'sweetalert2';
import { AiOutlineLoading } from 'react-icons/ai';


function Login() {

    const [isLoading, setIsLoading] = useState(false);
    const [InputEmail,setInputEmaill]=useState('')
    const [InputPass,setInputPass]=useState('')

    const [PaymentLoading,setPaymentLoading]=useState(false)
    const [ProfileLoading,setProfileLoading]=useState(false)

    async function GetProfilefromBackend(session)
    {
        setProfileLoading(true)
        const JWT=session.access_token
        const bearerKey = `Bearer ${JWT}`
        const res=await fetch('https://postbud-backend-2c329618647b.herokuapp.com/profile',{
            method:'GET',
            headers: {
                'Authorization': bearerKey
            },
        })

        const response=await res.json()
        if(response.length>0)
        {
            localStorage.setItem('ProfileData', JSON.stringify(response));
            window.location.replace('/dashboard')
        }
        else
        { 
            window.location.replace('/profile')
    
        }
    }

    async function GetPaymentStripe(session)
    {
        setPaymentLoading(true)
        try{
        const JWT=session.access_token
        const bearerKey = `Bearer ${JWT}`
        const res=await fetch('https://postbud-backend-2c329618647b.herokuapp.com/stripe/getCurrentPlan',{
            method:'GET',
            headers: {
                'Authorization': bearerKey
            },
        })
        
        const response=await res.json()

        if(response==null)
        {
            window.location.replace('/price')
        }
        else
        {
            setPaymentLoading(false)
            GetProfilefromBackend(session)
        }
        }
        catch(e){
            window.location.replace('/price')
            return
        }
        
        
        
    }

    async function loginSupabase(){
        setIsLoading(true)
        const { data, error } = await supabase.auth.signInWithPassword({
            email: InputEmail,
            password: InputPass,
        })

        console.log(error)
        setIsLoading(false)

        if(error)
        {
            Swal.fire({
            title:'Failure',
            text:error.message,
            icon:'error',
            confirmButtonText:'OK'
            })
        }
        else
        {
            const session = JSON.parse(localStorage.getItem('sb-fjuizzmuqvpsqhjdapfj-auth-token'))
            if (session){
                const accessToken = session.access_token;

                Swal.fire({
                    title:'Success',
                    text:'Signed in Successfully',
                    icon:'success',
                    confirmButtonText:'OK'
                }).then(function(){
                    GetPaymentStripe(session)
                })

            }
            else{
            // Error, maybe wonrg password
            Swal.fire({
                title:'Failure',
                text:'Incorrect Email or Password',
                icon:'error',
                confirmButtonText:'OK'
            })
            console.log("Error | Wrong passwrod ?")
            }
        }
    }

    function LoginFunction()
    {
        if(InputEmail===''||InputPass==='')
        {
            Swal.fire({
            title:'Error',
            text:'Please fill all the fields',
            icon:'warning',
            confirmButtonText:'OK'
            })
        }
        else
        {
            loginSupabase()
        }
    }


    
    function RedirectSignup()
    {
        window.location.replace('/signup')
    }
    function RedirectProfile()
    {
        window.location.replace('/profile')
    }

  return (
    <div class='w-full min-h-screen'>
        {
            PaymentLoading==false && ProfileLoading==false?
                <div class='w-full h-screen bg-white'>
                    <div class='w-full absolute flex justify-center items-center sm:p-5 p-3 bg-gray-100/95'>
                        <img src={logo} class='sm:w-14 w-8 mr-5'/>
                        <text class='sm:text-3xl text-2xl font-font1 font-bold '>PostBud</text>
                    </div>
                    <div class='w-full h-full flex justify-center items-end px-8 py-10'>
                        <div class='lg:w-1/2 md:w-2/3 w-full h-fit flex flex-col items-center space-y-16'>
                            <text class='text-2xl font-font2 font-bold'>Log in</text>
                            <div class='w-full flex flex-col space-y-5'>
                                <div class='w-full flex flex-col'>
                                    <text class='px-2 text-lg font-font2'>Email</text>
                                    <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='abc@mail.com' onChange={(e)=>setInputEmaill(e.target.value)}/>
                                </div>
                                <div class='w-full flex flex-col'>
                                    <text class='px-2 text-lg font-font2'>Password</text>
                                    <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='............' type='password' onChange={(e)=>setInputPass(e.target.value)}/>
                                </div>
                            </div>
                            <div class='w-full flex flex-col space-y-5'>
                                <button class='w-full py-4 flex justify-center items-center bg-gradient-to-r from-[#E5BA73] to-[#C58940] rounded-lg text-white font-bold font-font2 hover:text-black' onClick={LoginFunction}>
                                    Log in
                                    {
                                        isLoading==true?
                                        <AiOutlineLoading class='animate-spin ml-3'/>
                                        :
                                        <></>
                                    }
                                </button>
                                <div class='flex items-center space-x-5'>
                                    <div class='w-full h-0 border-[2px]'></div>
                                    <text class='text-gray-500 font-font2'>or</text>
                                    <div class='w-full h-0 border-[2px]'></div>
                                </div>
                                <button class='w-full py-4 bg-white rounded-lg border-[2px] border-[#E5BA73] text-[#E5BA73] font-bold font-font2 hover:text-black hover:bg-gray-100/90' onClick={RedirectSignup}>Sign up</button>
                            </div>

                        </div>
                    </div>
                </div>
            :
            <div class='w-full h-screen flex flex-col justify-center items-center bg-gray-200 space-y-10'>
                <AiOutlineLoading size='5rem' class='animate-spin backdrop-blur-lg'/>
                {
                    PaymentLoading==true?
                        <text class='font-font1 text-2xl font-light'>Getting Payment details</text>
                    :
                        <text class='font-font1 text-2xl font-light'>Fetching Profile</text>
                }
            </div>
        } 
    </div>
  )
}

export default Login