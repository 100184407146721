import React, { useEffect, useState } from 'react'
import { AiOutlineLoading, AiOutlinePlus } from 'react-icons/ai'
import { BsBoxArrowLeft } from 'react-icons/bs'
import logo from '../Images/logo.png'
import './UserProfile.css'
import { v4 as uuidv4 } from 'uuid'
import supabase from '../config/Supabase'
import { upload } from '@testing-library/user-event/dist/upload'
import Swal from 'sweetalert2'
import Navbar from './Navbar'

const CompanyURL = 'https://fjuizzmuqvpsqhjdapfj.supabase.co/storage/v1/object/public/logos/'

function UserProfile() {

    const [selectedImage, setSelectedImage] = useState(null);
    const [UploadImage, setUploadImage] = useState(null)
    const [TotalURL, setTotalURL] = useState('')
    const [UserID, setUserID] = useState('')
    const [JWT, setJWT] = useState('')

    const [CompanyName, setCompanyName] = useState('')
    const [TradeType, setTradeType] = useState('')
    const [Language, setLanguage] = useState('')

    const [ProfileData, setProfileData] = useState([])
    const [PaymentLoading, setPaymentLoading] = useState(false)
    const [ProfileLoading, setProfileLoading] = useState(false)

    async function handleImageUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        setUploadImage(file)
    };

    async function RedirectDashboard() {

        if (UploadImage == null || CompanyName == '' || TradeType == '' || Language == '') {
            Swal.fire({
                title: 'warning',
                text: 'PLease fill all the fields',
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        }

        else {
            setProfileLoading(true)

            const { data, error } = await supabase
                .storage
                .from('logos')
                .upload(UserID + '/' + uuidv4(), UploadImage)

            if (data) {
                getLogos()
            }
            else {
                console.log(error)
            }

        }
    }

    async function getLogos() {
        const { data, error } = await supabase
            .storage
            .from('logos')
            .list(UserID + '/', {
                limit: 1,
                offset: 0,
                sortBy: { column: 'name', order: 'asc' }
            })

        if (data != null) {
            console.log(data)
            setUploadImage(data)
            setTotalURL(CompanyURL + UserID + '/' + data[0].name)

        }
        else {
            console.log(error)
        }
    }

    async function SendProfileToBackend() {
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/profile', {
            method: 'POST',
            body: JSON.stringify(ProfileData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerKey
            },
        })

        const response = await res.json()
        if (response == 'Profile inserted successfully') {
            Swal.fire({
                title: 'Success',
                text: 'Data saved successfully',
                icon: 'success',
                confirmButtonText: 'Proceed'
            }).then(function () {
                localStorage.setItem('ProfileData', JSON.stringify(ProfileData));
                window.location.replace('/dashboard')
            })
        }
        if(response === 'Profile updated successfully'){
            Swal.fire({
                title: 'Success',
                text: 'Data updated successfully',
                icon: 'success',
                confirmButtonText: 'Proceed'
            }).then(function () {
                localStorage.setItem('ProfileData', JSON.stringify(ProfileData));
                window.location.replace('/dashboard')
            })
        }
        else {
            Swal.fire({
                title: 'Error',
                text: 'Data saved unsuccessfully',
                icon: 'error',
                confirmButtonText: 'TRY AGAIN'
            })
            setProfileLoading(false)
        }
    }

    async function GetPaymentStripe(session) {
        setPaymentLoading(true)
        const JWT = session.access_token
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/stripe/getCurrentPlan', {
            method: 'GET',
            headers: {
                'Authorization': bearerKey
            },
        })

        const response = await res.json()

        if (response == null) {
            Swal.fire({
                title: 'Payment subscription not found',
                text: 'Proceeding to plans & pricing....',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            }).then(function () {
                window.location.replace('/price')
            })
        }
        else {
            setPaymentLoading(false)
        }
    }

    useEffect(() => {
        const session = JSON.parse(localStorage.getItem('sb-fjuizzmuqvpsqhjdapfj-auth-token'))
        if (!session) {
            Swal.fire({
                title: 'Not Logged in',
                text: 'Proceeding to log in....',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            }).then(function () {
                window.location.replace('/login')
            })
        }
        else {
            setUserID(session.user.id)
            setJWT(session.access_token)
            GetPaymentStripe(session)
        }
    }, [])

    useEffect(() => {
        if (UploadImage != null && CompanyName != '' && TradeType != '' && Language != '') {
            setProfileData(obj => [...obj, { companyName: CompanyName, logoUrl: TotalURL, tradeCheck: TradeType, languageCheck: Language }])
            localStorage.setItem('ProfileData', JSON.stringify(ProfileData));
        }
    }, [TotalURL])

    useEffect(() => {
        if (ProfileData.length > 0) {
            SendProfileToBackend()
        }
    }, [ProfileData])

    return (

        <div class='w-full min-h-screen'>
            {
                PaymentLoading == false && ProfileLoading == false ?
                    <div class='w-full h-screen bg-white'>
                        <Navbar check={1} dashCheck={1} />
                        <div class='w-full h-full flex pt-24'>
                            <div class='w-1/3 h-full flex flex-col justify-center items-center border-r-[2px] space-y-3'>
                                <div className="circle-file-input">
                                    {selectedImage ? (
                                        <img src={selectedImage} alt="Uploaded" className="preview-image" />
                                    ) : (
                                        <div className="placeholder-text">
                                            <AiOutlinePlus size='3rem' color='#C58940' />
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={handleImageUpload}
                                        className="file-input"
                                    />
                                </div>
                                <div class='flex flex-col items-center'>
                                    <text class='text-lg font-font2 font-bold'>Company logo</text>
                                    <text class='text-sm text-gray-400'>upload an image</text>
                                </div>
                            </div>
                            <div class='w-2/3 h-full text-black flex flex-col sm:p-8 space-y-20'>
                                <text class='text-3xl font-bold font-font1'>Profile Creation</text>
                                <div class='w-full flex flex-col space-y-7 '>
                                    <div class='w-full flex flex-col'>
                                        <text class='px-2 text-lg font-bold font-font2'>Company name</text>
                                        <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='Name of company..' onChange={(e) => setCompanyName(e.target.value)} />
                                    </div>
                                    <div class='w-full flex flex-col'>
                                        <text class='px-2 text-lg font-bold font-font2'>Trade type</text>
                                        <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='Trade type..' onChange={(e) => setTradeType(e.target.value)} />
                                    </div>
                                    <div class='w-full flex flex-col'>
                                        <text class='px-2 text-lg font-bold font-font2'>Language preferance</text>
                                        <select class='w-full py-2 rounded-full bg-gray-100/95 border-[2px] px-4 outline-none text-gray-400' onChange={(e) => setLanguage(e.target.value)}>
                                            <option class='hidden'>Select a language</option>
                                            <option >British</option>
                                            <option>American</option>
                                        </select>
                                    </div>
                                </div>
                                <div class='w-full flex justify-between '>
                                    <button class='flex items-center md:text-lg text-sm font-font1 font-semibold' onClick={() => window.location.replace('/')}>
                                        <BsBoxArrowLeft size='1.5rem' class='mr-3' />
                                        Return to home page
                                    </button>
                                    <button class='md:px-16 px-6 md:py-3 py-2 bg-gradient-to-r from-[#E5BA73] to-[#C58940] rounded-lg text-white font-bold font-font2' onClick={RedirectDashboard}>Save profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div class='w-full h-screen flex flex-col justify-center items-center bg-gray-200 space-y-10'>
                        <AiOutlineLoading size='5rem' class='animate-spin backdrop-blur-lg' />
                        {
                            PaymentLoading == true ?
                                <text class='font-font1 text-2xl font-light'>Getting Payment details</text>
                                :
                                <text class='font-font1 text-2xl font-light'>Saving Profile</text>
                        }
                    </div>
            }

        </div>
    )
}

export default UserProfile