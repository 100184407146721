import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import UserProfile from "./Components/UserProfile";
import { Fragment } from "react";
import SignUp from "./Components/Signup";
import Pricing from "./Components/Pricing";

function App() {
  return (
    <div class='w-full min-h-screen flex flex-col'>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path="/" Component={SignUp}/>
            <Route exact path='/signup' Component={SignUp} />
            <Route exact path='/login' Component={Login} />
            <Route exact path='/dashboard' Component={Dashboard} />
            <Route exact path='/profile' Component={UserProfile} />
            <Route exact path='/price' Component={Pricing}/>
          </Routes>
        </Fragment>
      </BrowserRouter>

    </div>
  );
}

export default App;
