import React, { useEffect, useState } from 'react'
import SingleContent from './SingleContent'
import SocialPost from './SocialPost'
import AdvertisingScript from './AdvertisingScript'
import ProductDescription from './ProductDescription'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import mergeImages from 'merge-images';
import { AiOutlineLoading, AiOutlineMenu } from 'react-icons/ai'

import { AiOutlineLike } from 'react-icons/ai'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { BsWrenchAdjustable } from 'react-icons/bs'
import Canvas from './Canvas'

function Dashboard() {

    const [Option, setOption] = useState(0)
    const [Submit, setSubmit] = useState(0)
    const [GPTOutput, setGPTOutput] = useState([])
    const [DisplayImage, setDisplayImage] = useState([])

    const [ProfileData, setProfileData] = useState([{ companyName: '', logoUrl: '', tradeCheck: '', languageCheck: '' }])
    const [JWT, setJWT] = useState('')

    const [ActualOutput, setActualOutput] = useState('')
    const [OutputText, setOutputText] = useState('')

    const [paymentLoading, setPaymentLoading] = useState(false)
    const [BillingLoading, setBillingLoading] = useState(false)

    const [OptionArray, setOptionArray] = useState([
        { color: '#123456', title: 'Social Media Job Post', desc: 'Answer a few questions about a job you’ve completed and upload any images. PostBud shapes your answers into a compelling story, resizes your images and adds your logo to them, all ready to be posted!' },
        { color: '#123456', title: 'Advertising Script', desc: 'Answer some short questions about your product or service and PostBud will create a persuasive ad script. Perfect for Facebook Ads, Google Ads, or even a radio voiceover, anything you need ad copy for!' },
        { color: '#123456', title: 'Product or Service Description', desc: 'Answer some simple questions telling PostBud about your product or service. PostBud will convert your answers into an engaging, detailed description – ideal for website content or intriguing social media posts!' },
    ])


    function RemoveEmojis(input) {
        // Emoji regex pattern
        const emojiRegex = /[\u{1F300}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F601}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2300}-\u{23FF}\u{2190}-\u{21FF}\u{2B00}-\u{2BFF}\u{25A0}-\u{25FF}\u{2900}-\u{297F}\u{1F30D}-\u{1F567}]/gu;
        return input.replace(emojiRegex, '');
    }


    async function FetchSocialPostResponse() {
        const bearerKey = `Bearer ${JWT}`
        console.log(GPTOutput)
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/socialPost', {
            method: 'POST',
            body: JSON.stringify(GPTOutput),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerKey
            },
        })

        const reader = res.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let temp = ""; // we are getting data from gpt here
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            temp += decoder.decode(value);
            console.log(temp)
            setActualOutput(RemoveEmojis(temp))
        }
        console.log("done");
        console.log(temp)
    }

    async function FetchAdScriptResponse() {
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/ad', {
            method: 'POST',
            body: JSON.stringify(GPTOutput),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerKey
            },
        })

        const reader = res.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let temp = ""; // we are getting data from gpt here
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            temp += decoder.decode(value);
            console.log(temp)
            setActualOutput(temp)
        }
        console.log("done");
        console.log(temp)
    }

    async function FetchDescriptionResponse() {
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/product', {
            method: 'POST',
            body: JSON.stringify(GPTOutput),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerKey
            },
        })

        const reader = res.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let temp = ""; // we are getting data from gpt here
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            temp += decoder.decode(value);
            console.log(temp)
            setActualOutput(temp)
        }
        console.log("done");
        console.log(temp)
    }

    async function getBilling() {
        setBillingLoading(true)
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/stripe/getDashboard', {
            method: 'GET',
            headers: {
                'Authorization': bearerKey
            },
        })

        const response = await res.json()
        window.location.href = response
    }

    async function GetPaymentStripe(session) {
        setPaymentLoading(true)

        const JWT = session.access_token
        const bearerKey = `Bearer ${JWT}`
        const res = await fetch('https://postbud-backend-2c329618647b.herokuapp.com/stripe/getCurrentPlan', {
            method: 'GET',
            headers: {
                'Authorization': bearerKey
            },
        })
        const response = await res.json()

        if (response == null) {
            Swal.fire({
                title: 'Payment subscription not found',
                text: 'Proceeding to plans & pricing....',
                icon: 'error',
                timer: 2000,
                didOpen: () => {
                    Swal.showLoading()
                }
            }).then(function () {
                window.location.replace('/price')
            })
        }
        else {
            setPaymentLoading(false)
        }

        return true
    }

    useEffect(() => {
        if (Submit == 1) {
            FetchSocialPostResponse()
            setOutputText('Social media post')
        }
        else if (Submit == 2) {
            FetchAdScriptResponse()
            setOutputText('Advertisement script')
        }
        else if (Submit == 3) {
            FetchDescriptionResponse()
            setOutputText('Product/Service Description')
        }
    }, [GPTOutput])

    useEffect(() => {
        const session = JSON.parse(localStorage.getItem('sb-fjuizzmuqvpsqhjdapfj-auth-token'))
        const Pdata = JSON.parse(localStorage.getItem('ProfileData'))
        if (session) {
            setJWT(session.access_token)

            var waitcheck = false
            waitcheck = GetPaymentStripe(session)
            if (Pdata) {
                setProfileData(Pdata)
            }
            else if (waitcheck == true) {
                Swal.fire({
                    title: 'Profile not found',
                    text: 'Proceeding to profile creation....',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                }).then(function () {
                    window.location.replace('/profile')
                })
            }
        }
        else {
            Swal.fire({
                title: 'Not Logged in',
                text: 'Proceeding to log in....',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            }).then(function () {
                window.location.replace('/login')
            })
        }
    }, [])

    useEffect(() => {
        console.log(ProfileData)
    }, [ProfileData])

    const [SideBar, setSidebar] = useState(false)

    return (
        <div class='w-full min-h-screen'>
            {
                paymentLoading == false && BillingLoading == false ?
                    <>
                        <Navbar check={1} dashCheck={1} />
                        {
                            <button class='w-fit md:hidden absolute mt-24 ml-5 z-50' onClick={() => { setSidebar(!SideBar) }}>
                                <AiOutlineMenu size='1.5rem' />
                            </button>
                        }
                        {
                            SideBar == true ?
                                <div class='w-full md:hidden h-fit flex flex-col px-5 pt-40 pb-5 border-[2px] rounded-lg justify-between items-center absolute bg-white space-y-5'>
                                    <div class='w-full flex flex-col space-y-2'>
                                        <button class='px-10 w-full text-center rounded-lg bg-gray-100/95 py-2 ' onClick={() => { window.location.reload() }}>Dashboard</button>
                                        <button class='px-10 w-full text-center rounded-lg bg-gray-100/95 py-2' onClick={getBilling}>Billing</button>
                                    </div>
                                    <div class='w-full h-full flex flex-col bg-gray-100/95 p-3 items-center rounded-lg border-[2px] border-[#E5BA73] space-y-14'>
                                        <text class='text-lg text-center font-font2 font-bold text-[#C58940]'>Profile Info</text>
                                        <div class='w-full flex flex-col space-y-3'>
                                            <div class='w-full flex flex-col items-center bg-white p-2 rounded-lg border-[2px]'>
                                                <text class='font-font1 font-bold text-lg'>{ProfileData[0].companyName}</text>
                                                <text class='font-font2 text-xs'>(Company Name)</text>
                                            </div>
                                            <div class='w-full flex flex-col items-center bg-white p-2 rounded-lg border-[2px]'>
                                                <text class='font-font1 font-bold text-lg'>{ProfileData[0].tradeCheck}</text>
                                                <text class='font-font2 text-xs'>(Trade type)</text>
                                            </div>
                                            <div class='w-full flex flex-col items-center bg-white p-2 rounded-lg border-[2px]'>
                                                <text class='font-font1 font-bold text-lg'>{ProfileData[0].languageCheck}</text>
                                                <text class='font-font2 text-xs'>(Language)</text>
                                            </div>
                                            <div class='w-full flex flex-col items-center bg-white p-2 rounded-lg border-[2px]'>
                                                <img src={ProfileData[0].logoUrl} class='w-20' />
                                                <text class='font-font2 text-xs'>(Logo)</text>
                                            </div>
                                            <button
                                            class='w-full bg-[#E5BA73] rounded-lg py-2 text-white font-font1 font-semibold'
                                            onClick={() => { window.location.replace('/profile') }}
                                        >
                                            Edit Profile
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div class='w-full min-h-screen bg-gray-100/95 flex md:px-20 px-5 pt-24 pb-8 space-x-8'>

                            <div class='h-fit w-fit md:flex hidden flex-col  p-5 space-y-10 rounded-lg font-font2'>
                                <div class='w-full h-full flex flex-col space-y-2 px-6 py-4 rounded-lg bg-white'>
                                    <button class='pr-20 pl-4 text-start rounded-lg bg-gray-100/95 py-2 ' onClick={() => { window.location.reload() }}>Dashboard</button>
                                    <button class='pr-20 pl-4 text-start rounded-lg bg-gray-100/95 py-2' onClick={getBilling}>Billing</button>
                                </div>
                                <div class='w-full h-full flex flex-col p-3 items-center bg-white rounded-lg border-[2px] border-[#E5BA73] space-y-14'>
                                    <text class='text-lg text-center font-font2 font-bold text-[#C58940]'>Profile Info</text>
                                    <div class='w-full flex flex-col space-y-3'>
                                        <div class='w-full flex flex-col items-center bg-gray-100/95 p-2 rounded-lg border-[2px]'>
                                            <text class='font-font1 font-bold text-lg'>{ProfileData[0].companyName}</text>
                                            <text class='font-font2 text-xs'>(Company Name)</text>
                                        </div>
                                        <div class='w-full flex flex-col items-center bg-gray-100/95 p-2 rounded-lg border-[2px]'>
                                            <text class='font-font1 font-bold text-lg'>{ProfileData[0].tradeCheck}</text>
                                            <text class='font-font2 text-xs'>(Trade Type)</text>
                                        </div>
                                        <div class='w-full flex flex-col items-center bg-gray-100/95 p-2 rounded-lg border-[2px]'>
                                            <text class='font-font1 font-bold text-lg'>{ProfileData[0].languageCheck}</text>
                                            <text class='font-font2 text-xs'>(Language)</text>
                                        </div>
                                        <div class='w-full flex flex-col items-center bg-gray-100/95 p-2 rounded-lg border-[2px]'>
                                            <img src={ProfileData[0].logoUrl} class='w-20' />
                                            <text class='font-font2 text-xs'>(Logo)</text>
                                        </div>
                                        <button
                                            class='w-full bg-[#E5BA73] rounded-lg py-2 text-white font-font1 font-semibold'
                                            onClick={() => { window.location.replace('/profile') }}
                                        >
                                            Edit Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {
                                Submit == 0 ?
                                    Option == 0 ?
                                        <>
                                            <div class='w-full flex flex-col space-y-8 font-font1'>
                                                <text class='text-3xl font-sans font-semibold'>Which type of content would you like to create today?</text>
                                                <div class='w-full flex flex-wrap'>
                                                    {
                                                        OptionArray.map((obj, index) =>
                                                            <SingleContent option={obj} setOption={setOption} index={index} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        [
                                            Option == 1 ?
                                                <SocialPost setSubmit={setSubmit} GPTOutput={GPTOutput} setGPTOutput={setGPTOutput} setDisplayImage={setDisplayImage} DisplayImage={DisplayImage} />
                                                :
                                                [
                                                    Option == 2 ?
                                                        <AdvertisingScript setSubmit={setSubmit} GPTOutput={GPTOutput} setGPTOutput={setGPTOutput} />
                                                        :
                                                        <ProductDescription setSubmit={setSubmit} GPTOutput={GPTOutput} setGPTOutput={setGPTOutput} />
                                                ]
                                        ]
                                    :
                                    <div class='w-full flex flex-col font-font2 font-semibold'>
                                        <div class='w-fit py-4 px-8 rounded-t-lg bg-gray-100/95 border-b-[2px] border-[1px] border-gray-300 border-b-black'>{OutputText}</div>
                                        <div class='w-full h-full flex flex-col rounded-b-lg rounded-tr-lg px-8 py-6 bg-white border-[1px] border-gray-300 space-y-5'>
                                            {
                                                Submit == 1 ?
                                                    DisplayImage.map(obj =>
                                                        <div class='w-full h-fit flex flex-col bg-gray-100/95 rounded-3xl'>
                                                            <text class='font-font1 text-lg pl-5 py-3'>{ProfileData[0].companyName}</text>
                                                            <div class='w-full flex'>
                                                                {/* <img src={obj} class='w-full h-full object-cover' />
                                                                <img src={ProfileData[0].logoUrl} class='absolute w-14 mr-2 mb-2' /> */}
                                                                <Canvas
                                                                    parentImage={obj}
                                                                    logoImage={ProfileData[0].logoUrl}
                                                                />
                                                            </div>
                                                            <text class='font-font2 pl-5 my-10 whitespace-pre-wrap'>{ActualOutput}</text>
                                                        </div>
                                                    )

                                                    :
                                                    [
                                                        Submit == 2 ?
                                                            <div class='w-full flex flex-col font-font2 font-semibold whitespace-pre-wrap'>
                                                                {ActualOutput}
                                                            </div>
                                                            :
                                                            <div class='w-full flex flex-col font-font2 font-semibold whitespace-pre-wrap'>
                                                                {ActualOutput}
                                                            </div>
                                                    ]
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </>
                    :
                    <div class='w-full h-screen flex flex-col justify-center items-center bg-gray-200 space-y-10'>
                        <AiOutlineLoading size='5rem' class='animate-spin backdrop-blur-lg' />
                        {
                            paymentLoading == true ?
                                <text class='font-font1 text-2xl font-light'>Getting Payment details</text>
                                :
                                <text class='font-font1 text-2xl font-light'>Getting Billing Information</text>
                        }
                    </div>
            }
        </div>
    )
}

export default Dashboard