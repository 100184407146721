import React from 'react'
import logo from '../Images/logo.png'
import supabase from '../config/Supabase'
import Swal from 'sweetalert2'

function Navbar(props) {

  async function LogOutFunction()
  {
    const {data,error}=await supabase.auth.signOut()
    localStorage.clear()
    Swal.fire({
      title: 'Logging Out',
      html: 'Please wait...', 
      timer: 3000, 
      showConfirmButton: false, 
      didOpen:()=>
      {
          Swal.showLoading()
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location.replace('/login')
      }
    });
    
  }

  return (
    <div class={props.check==1?'w-full fixed flex flex-row justify-between items-center bg-white text-black border-b-[2px] py-4 sm:pr-20 sm:pl-14 px-5 z-[999]':'w-full fixed flex flex-row justify-between items-center bg-black text-white py-4 pr-20 pl-14'}>
        <div class='flex items-center space-x-3'>
            <img src={logo} class='sm:w-10 w-8'></img>
            <text class='text-2xl font-font2 font-bold '>PostBud</text>
        </div>
        {
          props.dashCheck!=1 ? 
            <div class='flex space-x-3 font-font2'>
                <button class={props.check==1?'py-2 px-12 bg-gradient-to-r from-[#E5BA73] to-[#C58940] text-white rounded-lg':'py-2 px-12 bg-gradient-to-r from-[#E5BA73] to-[#C58940] text-white rounded-lg'}>Sign up</button>
                <button class={props.check==1?'py-2 px-12 border-[2px] border-[#E5BA73] rounded-lg':'py-2 px-12 border-[2px] border-[#E5BA73] rounded-lg'}>Log in</button>
            </div>
          :
            <button class='sm:py-2 py-1 sm:px-12 px-4 border-[2px] border-[#E5BA73] rounded-lg' onClick={LogOutFunction}>Log out</button>
        }
    </div>
  )
}

export default Navbar