import React, { useState } from 'react'
import SingleInput from './SingleInput'
import Swal from 'sweetalert2'

function ProductDescription(props) {
    const Questions=['What is this for? (Social media post, website page etc.) *','Is it a product or a service? *','What is the name of the product or service? *','What does it do? *','Any additional information (the more the better!)']
    const [Answers,setAnswers]=useState({reason:'',item:'',nameofProduct:'',description:'',additionalInformation:''})    
    const [AnswerNames,setAnswerNames]=useState(['reason','item','nameofProduct','description','additionalInformation'])
    
    function HandleSubmit()
    {
        var check=false
        if(Answers.reason==''||Answers.item==''||Answers.nameofProduct==''||Answers.description=='')
        {
            check=true
        }

        if(check==true)
        {
            Swal.fire({
                title:'warning',
                text:'Check that all of the relevant fields are entered correctly.',
                icon:'warning',
                confirmButtonText:'OK'
            })
        }
        else
        {
            props.setGPTOutput([])
            props.setGPTOutput(obj=>[...obj,Answers])
            props.setSubmit(3)
        }
    }
    return (
    <div class='w-full flex flex-col space-y-8'>
        <text class='text-3xl font-semibold font-font1 bg-clip-text text-transparent bg-gradient-to-r from-[#C58940] to-[#E5BA73]'>Create A Product Or Service Description</text>
        
        <div class='w-full flex flex-col bg-white p-3 rounded-xl px-3'>
            <div class='w-full grid grid-cols-2 font-font2'>
                {
                    Questions.map((obj,index)=>
                        index!=1?
                        <SingleInput question={obj} check={2} type={2} setAnswers={setAnswers} Answers={Answers} AnswerNames={AnswerNames} index={index}/> 
                        :
                        <SingleInput question={obj} check={3} setAnswers={setAnswers} Answers={Answers} AnswerNames={AnswerNames} index={index}/>
                    )
                }     
            </div>
            <button class='w-fit px-12 py-2 ml-3 rounded-lg bg-blue-400 text-white bg-gradient-to-r from-[#C58940] to-[#E5BA73]' onClick={HandleSubmit}>Submit</button>
        </div>
    </div>
  )
}

export default ProductDescription