import React, { useState } from 'react'
import SingleInput from './SingleInput'
import Swal from 'sweetalert2'

function AdvertisingScript(props) {
    const Questions=['What is the ad for? (Facebook, Google, Radio script etc.) *','What is your trade? *','Describe the product(s) or service(s)? *','Any additional information (the more the better!)']
    const [Answers,setAnswers]=useState({purpose:'',lineofBusiness:'',product:'',additionalInfo:''})    
    const [AnswerNames,setAnswerNames]=useState(['purpose','lineofBusiness','product','additionalInfo'])
    
    function HandleSubmit()
    {
        var check=false
        if(Answers.purpose==''||Answers.lineofBusiness==''||Answers.product=='')
        {
            check=true
        }

        if(check==true)
        {
            Swal.fire({
                title:'warning',
                text:'Check that all of the relevant fields are entered correctly.',
                icon:'warning',
                confirmButtonText:'OK'
            })
        }
        else
        {
            props.setGPTOutput([])
            props.setGPTOutput(obj=>[...obj,Answers])
            props.setSubmit(2)
        }
    }
    
    return (
    <div class='w-full flex flex-col space-y-8'>
        <text class='text-3xl font-semibold font-font1 bg-clip-text text-transparent bg-gradient-to-r from-[#C58940] to-[#E5BA73]'>Creating an Ad Script</text>
        
        <div class='w-full flex flex-col bg-white p-3 rounded-xl px-3'>
            <div class='w-full grid grid-cols-2 font-font2'>
                {
                    Questions.map((obj,index)=>
                        <SingleInput question={obj} check={2} type={2} setAnswers={setAnswers} Answers={Answers} AnswerNames={AnswerNames} index={index}/> 
                    )
                }     
            </div>
            <button class='w-fit px-12 py-2 ml-3 rounded-lg text-white  bg-gradient-to-r from-[#C58940] to-[#E5BA73]' onClick={HandleSubmit}>Submit</button>
        </div>
    </div>
  )
}

export default AdvertisingScript