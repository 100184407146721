import React, { useEffect, useState } from 'react'
import SingleInput from './SingleInput'
import Swal from 'sweetalert2'

function SocialPost(props) {
    const Questions = ['What was your customers Name or Company Name? *', 'Where was the job located? *', 'What kind of work did the customer need? *', 'How did the customer hear about you or your services? *', 'Were there any other companies or specialists involved in the job? If yes, who were they and what was their role?', 'Can you describe the customers situation or problem before you started the job?', 'Could you walk us through the process of carrying out the work? Were there any significant milestones or turning points?', 'Were there any memorable moments or stories that unfolded during the job?', 'Did you encounter any challenges or unexpected issues during the project? How did you address them?', 'What was the clients reaction to the completed work? Have they given any feedback or testimonials?']
    const [Answers, setAnswers] = useState({ customerName: '', location: '', workReq: '', awareness: '', companions: '', problemofCustomer: '', milestones: '', memorableMoments: '', issues: '', feedbackofClient: '' })
    const [AnswerNames, setAnswerNames] = useState(['customerName', 'location', 'workReq', 'awareness', 'companions', 'problemofCustomer', 'milestones', 'memorableMoments', 'issues', 'feedbackofClient'])
    const [Pics, setPics] = useState([])

    function HandleSubmit() {
        var check = false
        if (Answers.customerName == '' || Answers.location == '' || Answers.workReq == '' || Answers.awareness == '') {
            check = true
        }

        if (check == true) {
            Swal.fire({
                title: 'warning',
                text: 'Check that all of the relevant fields are entered correctly.',
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        }
        else {
            props.setGPTOutput([])
            props.setGPTOutput(obj => [...obj, Answers])
            props.setSubmit(1)
        }
    }

    function HandleImage(event) {
        if (event.target.files) {
            // Convert FileList to an array and map to URLs
            const uploadedImages = Array.from(event.target.files).map(file => URL.createObjectURL(file));
            
            // Set the state with new images
            setPics(prevPics => [...prevPics, ...uploadedImages]);
            props.setDisplayImage(prevImages => [...prevImages, ...uploadedImages]);
        }
    }

    return (
        <div class='w-full flex flex-col space-y-8'>
            <text class='text-3xl font-font1 font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#C58940] to-[#E5BA73]'>Create A Social Post</text>

            <div class='w-full flex flex-col bg-white p-3 rounded-xl px-3'>
                <div class='w-full grid grid-cols-2 font-font2'>
                    {
                        Questions.map((obj, index) =>
                            index < 4 ?
                                <SingleInput question={obj} check={1} type={1} setAnswers={setAnswers} Answers={Answers} AnswerNames={AnswerNames} index={index} />
                                :
                                <SingleInput question={obj} check={2} type={1} setAnswers={setAnswers} Answers={Answers} AnswerNames={AnswerNames} index={index} />
                        )
                    }
                    <div class='col-span-2 flex flex-col px-3 mb-8'>
                        <text class='font-semibold mb-2'>Can you share some photos of the job? Before, during, and after pictures if available would be great! *</text>
                        <div class='border-[1px] rounded-xl p-1'>
                            <div class='p-4 rounded-xl  bg-gray-100/95'>
                                <input type='file' multiple onChange={HandleImage} />
                            </div>
                        </div>
                    </div>
                    <button class='w-fit px-12 py-2 ml-3 rounded-lg bg-gradient-to-r from-[#C58940] to-[#E5BA73] text-white' onClick={HandleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SocialPost