import React from 'react'

function SingleInput(props) {

    function HandleChange(event) {
        props.setAnswers({ ...props.Answers, [event.target.name]: event.target.value })
    }

    function handleRadioButton(event) {
        props.setAnswers({ ...props.Answers, [event.target.name]: event.target.value })
    }
    return (
        <>
            {
                props.check == 1 ?
                    <div class='md:col-span-1 col-span-2 flex flex-col px-3 mb-8'>
                        <text class='font-semibold mb-2'>{props.question}</text>
                        <textarea class='rounded-lg bg-transparent border-[2px] py-2 px-2 outline-[#E5BA73]' rows='1' name={props.AnswerNames[props.index]} onChange={HandleChange}></textarea>
                    </div>
                    :
                    [
                        props.check == 2 ?
                            <div class='col-span-2 flex flex-col px-3 mb-8'>
                                <text class='font-semibold mb-2'>{props.question}</text>
                                <textarea class='rounded-lg bg-transparent border-[2px] outline-[#E5BA73] px-2 pt-2' name={props.AnswerNames[props.index]} onChange={HandleChange} ></textarea>
                            </div>
                            :
                            <div class='col-span-2 flex flex-col px-3 mb-8'>
                                <text class='font-semibold mb-2'>{props.question}</text>
                                <div class='flex space-x-5'>
                                    <label>
                                        <input type="radio" name="item" value="Product" onChange={handleRadioButton} />
                                        Product
                                    </label>
                                    <label>
                                        <input type="radio" name="item" value="Service" onChange={handleRadioButton} />
                                        Service
                                    </label>
                                </div>
                            </div>
                    ]
            }
        </>
    )
}

export default SingleInput