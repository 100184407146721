import { useEffect, useState } from 'react'

function Canvas({ parentImage, logoImage }) {
    const [image, setImage] = useState(null)

    useEffect(() => {
        if (parentImage && logoImage) {
            const canvas = document.createElement('canvas')
            canvas.width = 300
            canvas.height = 300

            const context = canvas.getContext('2d')
            const parent = new Image()
            parent.src = parentImage
            parent.onload = () => {
                context.drawImage(parent, 0, 0, canvas.width, canvas.height)
                const logo = new Image()
                logo.crossOrigin = 'anonymous'
                logo.src = logoImage
                logo.onload = () => {
                    context.drawImage(logo, 236, 236, 64, 64)
                    setImage(canvas.toDataURL())
                }
            }
        }
    }, [parentImage, logoImage])

    const downloadImage = () => {
        const link = document.createElement('a')
        link.download = 'image.png'
        link.href = image
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div style={{ position: 'relative', top: 0, left: 0, width: '500px', height: '500px' }}>
            {parentImage && <img
                onClick={downloadImage}
                src={parentImage} alt="Parent Image" style={{ position: 'absolute', top: 0, left: 0, width: '500px', height: '500px' }} />}
            {logoImage && <img src={logoImage} alt="Logo Image" style={{ position: 'absolute', bottom: 10, right: 10, width: '48px', height: '48px' }} />}
            {image && <img
                onClick={downloadImage}
                src={image} alt="Combined Image" />}
        </div>
    )
}

export default Canvas