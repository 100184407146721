import React from 'react'
import icon1 from '../Images/likeIcon.png'
import icon2 from '../Images/SpeakerIcon.png'
import icon3 from '../Images/wrenchIcon.png'

function SingleContent(props) {
  function OpenOption()
  {
    if(props.index==0)
    {
      props.setOption(1)
    }
    else if(props.index==1)
    {
      props.setOption(2)
    }
    else if(props.index==2)
    {
      props.setOption(3)
    }
  }
  return (
    <button class='w-[40vh] flex flex-col bg-white p-8 rounded-lg space-y-4 text-start mr-2 mb-2' onClick={OpenOption}>
        {
          props.index==0?
            <img src={icon1} class='w-12'/>
          :
          [
            props.index==1?
              <img src={icon2} class='w-12'/>
            :
              <img src={icon3} class='w-12'/>
          ]
        }

        <text class='font-bold text-lg'>{props.option.title}</text>
        <text class='text-gray-400/95 font-font2'>{props.option.desc}</text>
    </button>
  )
}

export default SingleContent