import React, { useEffect } from 'react'
import logo from '../Images/logo.png'
import SinglePrice from './SinglePrice'
import Swal from 'sweetalert2'
import Navbar from './Navbar'

function Pricing() {

  useEffect(()=>{
    const session = JSON.parse(localStorage.getItem('sb-fjuizzmuqvpsqhjdapfj-auth-token'))
    if(!session)
    {
        Swal.fire({
            title:'Not Logged in',
            text:'Proceeding to log in....',
            icon:'error',
            timer:2000,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen:()=>
            {
                Swal.showLoading()
            }
        }).then(function(){
            window.location.replace('/login')
        })
    }
},[])

  return (
    <div class='w-full min-h-screen'>
        <Navbar check={1} dashCheck={1}/>
        <div class='w-full min-h-screen flex flex-col sm:px-16 px-8 pb-4 pt-24 justify-between space-y-10 items-center'>
                <text class='text-2xl font-font2 font-bold'>Plans & pricings</text>
            <div class='w-full min-h-full flex flex-row flex-wrap justify-center items-center'>
                <SinglePrice check={1}/>
            </div>
        </div>
    </div>
  )
}

export default Pricing