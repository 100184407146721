import React,{useState} from 'react'
import logo from '../Images/logo.png'
import supabase from '../config/Supabase'
import Swal from 'sweetalert2'
import { AiOutlineLoading } from 'react-icons/ai'

function SignUp() {
    const [Firstname,setFirstname]=useState('')
    const [Lastname,setLastname]=useState('')
    const [InputEmail,setInputEmaill]=useState('')
    const [InputPass,setInputPass]=useState('')

    const [Loading,setLoading]=useState(false)

    async function SignupSupabase()
    {
      setLoading(true)
    const { data, error } = await supabase.auth.signUp({
        email: InputEmail,
        password: InputPass,
      })

      if(data)
      {
        console.log(data)
        if(data.user==null)
        {
            Swal.fire({
                title:'Failure',
                text:'Incorrect Email or Password',
                icon:'error',
                confirmButtonText:'OK'
            })
        }
        else
        {
            Swal.fire({
                title:'Success',
                text:'Confirm your Email from your account',
                icon:'success',
                confirmButtonText:'OK'
            }).then(function()
            {
              window.location.replace('/login')
            })
        }
      }
      else
      {
        Swal.fire({
          title:'Failure',
          text:error.message,
          icon:'error',
          confirmButtonText:'OK'
        })
      }
      setLoading(false)
    }

    function SignupFunction()
    {
        if(InputEmail==''||InputPass==''||Firstname==''||Lastname=='')
        {
          Swal.fire({
            title:'Warning',
            text:'Please fill all the fields',
            icon:'warning',
            confirmButtonText:'OK'
          })
        }
        else if(InputPass.length<8)
        {
            Swal.fire({
                title:'Warning',
                text:'Password should contain at least 8 characters',
                icon:'warning',
                confirmButtonText:'OK'
              })
        }
        else{
          SignupSupabase()
        }
    }
    
    function RedirectLogin()
    {
        window.location.replace('/login')
    }

  return (
    <div class='w-full h-screen bg-white'>
        <div class='w-full absolute flex justify-center items-center sm:p-5 p-3 bg-gray-100/95'>
            <img src={logo} class='sm:w-14 w-8 mr-5'/>
            <text class='sm:text-3xl text-2xl font-font1 font-bold '>PostBud</text>
        </div>
        <div class='w-full h-full flex justify-center items-end px-8 py-10 '>
            <div class='md:w-2/3 lg:w-1/2 w-full h-fit flex flex-col items-center space-y-10'>
                <text class='text-2xl font-font2 font-bold'>Create an account</text>
                <div class='w-full flex flex-col space-y-5'>
                    <div class='w-full flex space-x-5'>
                        <div class='w-1/2 flex flex-col'>
                            <text class='px-2 text-lg font-font2'>First name</text>
                            <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='first name' onChange={(e)=>{setFirstname(e.target.value)}}/>
                        </div>
                        <div class='w-1/2 flex flex-col'>
                            <text class='px-2 text-lg font-font2'>Last name</text>
                            <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='last name' onChange={(e)=>{setLastname(e.target.value)}}/>
                        </div>
                    </div>
                    <div class='w-full flex flex-col'>
                        <text class='px-2 text-lg font-font2'>Email</text>
                        <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='abc@mail.com' onChange={(e)=>{setInputEmaill(e.target.value)}}/>
                    </div>
                    <div class='w-full flex flex-col'>
                        <text class='px-2 text-lg font-font2'>Password</text>
                        <input class='bg-gray-100/95 py-2 rounded-full border-[2px] outline-none px-4' placeholder='............' type='password' onChange={(e)=>{setInputPass(e.target.value)}}/>
                    </div>
                </div>
                <div class='w-full flex flex-col space-y-3'>
                    <button class='w-full py-4 flex justify-center items-center bg-gradient-to-r from-[#E5BA73] to-[#C58940] rounded-lg text-white font-bold font-font2 hover:text-black' onClick={SignupFunction}>
                      Sign up
                      {
                        Loading==true?
                        <AiOutlineLoading class='animate-spin ml-3'/>
                        :
                        <></>
                      }
                    </button>
                    <div class='flex items-center space-x-5'>
                        <div class='w-full h-0 border-[2px]'></div>
                        <text class='text-gray-500 font-font2'>or</text>
                        <div class='w-full h-0 border-[2px]'></div>
                    </div>
                    <button class='w-full py-4 bg-white rounded-lg border-[2px] border-[#E5BA73] text-[#E5BA73] font-bold font-font2 hover:bg-gray-100/90 hover:text-black' onClick={RedirectLogin}>Log in</button>
                </div>

            </div>
        </div>
    </div>
  )
}

export default SignUp